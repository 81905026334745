<template>
  <!-- If don't saved -->
  <ModalEmpty
    v-if="isNoSaved"
    @close="
      () => {
        isNoSaved = false
        leaveRoute = ''
      }
    "
  >
    <div class="w-500 p-30 flex ai-c jc-c fd-c ta-center">
      <h1 class="title">Не сохранили изменения</h1>
      <div class="t-grey-dark fs-16 mt-5 mb-30">
        Ваши изменения будут утерянны если их не сохранить, все равно хотите уйти?
      </div>

      <div class="grid ggap-10 w-100">
        <div class="grid gtc-2 ai-c ggap-10">
          <button class="btn grey jc-c" :disabled="isNoData" @click="saveAction">
            <BaseIcon class="ic-16 primary" icon="doc-2" />
            Сохранить в черновик
          </button>
          <button class="btn primary jc-c" :disabled="isNoData" @click="submitAction">
            <BaseIcon class="ic-16 white" icon="check-circle" />
            Отправить Юристам
          </button>
        </div>
        <button class="btn transparent-grey jc-c" @click="$router.push(leaveRoute)">
          <BaseIcon class="ic-16 red" icon="close-circle" />
          Не сохранять и уйти
        </button>
      </div>
    </div>
  </ModalEmpty>

  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <BaseIcon class="ic-24 primary" icon="plus-circle" />
        <h1 class="title">Новая заявка</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <!-- <div class="form-add grid gtc-1-auto"> -->
      <form class="form-add pb-40 pos-r z0" id="form-add">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
        <!-- Основные данные -->
        <section class="form-add__section grid ggap-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title">Основные данные</h2>
            <!-- <BaseIcon class="ic-16 grey" icon="alert" v-tippy="'Основные данные для подачи заявки юристам'" /> -->
          </div>
          <div class="grid gtc-2 ggap-20 ai-fs">
            <FormAutocomplete
              label="Тип заявки"
              :helper="`<p><b>Создать документ</b> - процесс создание документа с нуля, для точного формирование документа укажите особые условия.</p><p><b>Согласовать документ</b> - процесс состоит из этапов рассмотрение и согласование, можете в общих комментариях указать заинтересованных лиц для рассмотрение и согласование.</p>`"
              required
              :options="optionsOrderType"
              :isSearch="false"
              v-model="formData.type"
            />
            <!-- @focused="setFocus('type', $event)" -->
            <FormAutocomplete
              label="Проект"
              :helper="`<p><b>Проект это</b> - не официальное наименование дочерней компании Холдинга.</p><p>Укажите к какому проекту относится данный документ.</p>`"
              required
              :options="optionsProjects"
              v-model="formData.project"
            />
            <!-- @focused="setFocus('project', $event)" -->
          </div>
          <FormTextarea
            label="Особые условия"
            helper="Например: пожелание, условия оплаты, ответственность и т.д."
            placeholder="Особые условия (например: пожелание, условия оплаты, ответственность и т.д.)"
            class="big"
            required
            v-model="formData.special_conditions"
          />
          <!-- @focused="setFocus('special_conditions', $event)" -->
        </section>

        <!-- Файлы -->
        <section class="form-add__section grid ggap-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title">Файлы заявки</h2>
            <BaseIcon
              class="form-item__help ic-16 grey"
              icon="alert"
              v-tippy="'Прикрепите те файлы которые будут участвовать в создании или рассмотрении документа'"
            />
          </div>
          <FormFile v-model="formData.documents" />
        </section>

        <!-- Данные автора -->
        <section class="form-add__section grid ggap-20">
          <h2 class="title">Ваши данные</h2>
          <div class="flex ai-c ggap-10 fw">
            <span
              v-for="contact of contacts"
              :key="contact.id"
              class="box pt-5 pb-5 pl-10 pr-10 fs-14 flex ai-c ggap-5"
              v-tippy="'Ваш личный контакт из вашего профиля'"
            >
              <BaseIcon class="ic-18 grey" :icon="contact.type" />
              {{ contact.value }}
            </span>
            <span
              v-for="(contact, index) in formData.newContactList"
              :key="contact.value"
              class="box pt-5 pb-5 pl-10 pr-5 fs-14 flex ai-c ggap-10"
              v-tippy="'Новый контакт'"
            >
              <div class="flex ai-c ggap-5">
                <BaseIcon class="ic-18 primary" :icon="contact.type" />
                {{ contact.value }}
              </div>
              <button class="btn xsm transparent-grey rounded cube-20" @click="removeContact(index)">
                <BaseIcon class="ic-14 red" icon="close" />
              </button>
            </span>

            <!-- Новый контакт -->
            <div v-if="isAddContact" class="new-contact grid gtc-auto-1-auto ai-c ggap-5 box p-5 w-100">
              <FormSelect
                class="sm"
                label=""
                placeholder=""
                :options="contactTypesOptions"
                v-model="newContact.type"
                required
                @keydown.enter.prevent
              />
              <FormInput
                class="sm w-100"
                label=""
                placeholder="Введите"
                v-model="newContact.value"
                @keydown.enter.prevent
                required
              />
              <div class="flex ai-c ggap-5">
                <button class="btn primary-light sm" @click="addNewContact">Сохранить</button>
                <button class="btn cancel sm" @click="isAddContact = false">Отмена</button>
              </div>
            </div>

            <!-- Добавить контакт -->
            <button
              v-else
              class="btn primary-light sm"
              @click="isAddContact = !isAddContact"
              v-tippy="
                'Можно добавить контакты свои или другого участника, эти контакты НЕ будут сохранены в ваш профиль'
              "
            >
              <BaseIcon class="ic-16 primary" icon="plus-circle" /> Добавить контакты
            </button>
          </div>
          <!-- <div class="grid gtc-2">
              <FormDate label="Дедлайн" v-model="formData.deadline_date" />
            </div> -->
          <FormTextarea
            label="Общий комментарий"
            helper="Свободный текст, пример: Свяжитесь с Васей по этим контактам +7ххх он лучше знает что нужно."
            placeholder=""
            class="big"
            v-model="formData.comments"
          />
          <!-- @focused="setFocus('comment', $event)" -->
        </section>

        <!-- Контрагент и сумма -->
        <section class="form-add__section grid ggap-20">
          <h2 class="title">Дополнительно можете указать (не обязательно)</h2>

          <!-- Сумма -->
          <div class="form-add__section-box grid">
            <button
              class="form-add__section-box-btn btn flex ai-c jc-c"
              :class="{ active: isSummDoc, outline: isSummDoc, 'primary-light': !isSummDoc }"
              @click.prevent="isSummDoc = !isSummDoc"
            >
              <BaseIcon v-if="isSummDoc" class="ic-16 red" icon="delete" />
              <BaseIcon v-else class="ic-16 primary" icon="plus-circle" />
              {{ isSummDoc ? 'Убрать сумму договора' : 'Добавить сумму договора' }}
            </button>

            <div v-if="isSummDoc" class="form-add__section-box-body grid ggap-10">
              <BaseAlert type="yellow">
                Если вы знаете <b>Стоимость договора</b> то выберите его и впишите стоимость. Если вы не знаете
                стоимость договора то выберите <b>Оборот за 1 год</b> и впишите предпологаемую стоимость.
              </BaseAlert>
              <div class="grid gtc-1-auto ggap-20">
                <FormRadioGroup :items="summTypeItems" v-model.number="formData.priceType" />
                <FormSumm
                  label=""
                  placeholder="1 500 000"
                  required
                  v-model="formData.price"
                  v-model:currencyValue="formData.currency"
                />
              </div>
            </div>
          </div>

          <!-- Контрагент -->
          <div class="form-add__section-box grid">
            <div class="grid ggap-20">
              <div class="flex ai-c jc-sb">
                <h3 class="m-0">Контрагент</h3>
                <button
                  type="button"
                  @click.prevent.stop="isNewCounterparty = !isNewCounterparty"
                  class="btn transparent-grey sm pl-10"
                >
                  <FormFakeSwitch :isTrue="isNewCounterparty" />
                  Добавить нового
                </button>
              </div>

              <div v-if="isNewCounterparty" class="grid gtc-2 ggap-20 ai-fs">
                <FormInput
                  label="БИН / ИИН"
                  placeholder="Введите БИН или ИИН"
                  v-model="formData.counterparty_bin"
                  :maxlength="12"
                  required
                />
                <FormInput
                  label="Юр. наименование контрагента"
                  placeholder="Введите наименование контрагента"
                  v-model="formData.counterparty_name"
                  required
                />
              </div>
              <div v-else class="grid gtc-2 ggap-20 ai-fs">
                <FormAutocompleteCounterparty
                  class="f-1"
                  label="БИН / ИИН"
                  placeholder="Выберите"
                  :options="optionsCounterparties"
                  :isLoad="isCounterpartyLoad"
                  :isMultiple="false"
                  required
                  @search="searchCounterparties($event)"
                  v-model="formData.counterparty"
                >
                  <template #single="{ item }">
                    <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
                      <div class="grid gtc-auto-1 ai-c ggap-10">
                        <div class="cut">{{ item.text }}</div>
                      </div>
                    </div>
                    <div v-else class="t-grey-dark">-</div>
                  </template>
                </FormAutocompleteCounterparty>
                <ItemRead label="Юр. наименование контрагента">
                  <div
                    class="flex ai-c pt-10 fw-b"
                    :class="{ 't-grey-dark': !(formData.counterparty && formData.counterparty.name) }"
                  >
                    {{
                      formData.counterparty && formData.counterparty.name
                        ? formData.counterparty.name
                        : 'Нет наименования'
                    }}
                  </div>
                </ItemRead>
              </div>

              <FormTextarea
                label="Реквизиты контрагента"
                placeholder="Реквизиты и / или контактная информация"
                class="big"
                required
                v-model="formData.counterparty_info"
              />
            </div>
          </div>
        </section>

        <!-- Юзеры (только для юристов) -->
        <section v-if="isAnyLawyer" class="form-add__section grid ggap-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title mb-5">Выбор исполнителя и других участников заявки</h2>
            <small class="marker red">Только для Юристов</small>
          </div>
          <Peoples
            :isRead="false"
            :isFree="isFree"
            :objUsers="peoplesSingle"
            :freeUsers="peoplesList"
            :departmentUsers="peoplesDepartments"
          />
        </section>
      </form>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-sb ggap-20">
        <button class="btn grey" :disabled="isNoData" @click="saveAction">
          <BaseIcon class="ic-16 primary" icon="doc-2" />
          Сохранить в черновики
        </button>
        <button class="btn primary" :disabled="isNoData" @click="submitAction">
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Отправить заявку Юристам
        </button>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import orders from '@/api/modules/orders'
import { ref, reactive, watch, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { isInvalidForm, $busEmit, disableAllBtns } from '@/plugins'
import {
  ModalEmpty,
  ModalWrapper,
  BaseIcon,
  BaseAlert,
  FormInput,
  FormSumm,
  FormSelect,
  FormAutocomplete,
  FormAutocompleteCounterparty,
  FormTextarea,
  FormRadioGroup,
  FormFakeSwitch,
  FormFile,
  BaseLoad,
  Peoples,
  ItemRead
} from '@/components'

// Data
const store = useStore()
const router = useRouter()

// Form data
const formData = reactive({
  type: {}, // matching || create
  project: {},
  priceType: 'contract_price',
  price: '',
  currency: 'kzt', // kzt || usd
  special_conditions: '',
  counterparty: {},
  counterparty_id: '',
  counterparty_name: '',
  counterparty_bin: '',
  counterparty_info: '',
  comments: '',
  documents: [],
  newContactList: [],
  usersPreview: [],
  usersAgree: [],
  usersWatch: [],
  usersFinal: []
})
const summTypeItems = [
  {
    text: 'Стоимость договора',
    value: 'contract_price'
  },
  {
    text: 'Оборот за 1 год',
    value: 'annual_turnover'
  }
]
const optionsOrderType = [
  {
    text: 'Создать документ',
    value: 'create'
  },
  {
    text: 'Согласовать документ',
    value: 'matching'
  }
]

// Contacts data
const isAddContact = ref(false)
const newContact = reactive({
  type: 'phone',
  value: ''
})
const contactTypesOptions = [
  {
    id: 2,
    text: 'Телефон',
    value: 'phone'
  },
  {
    id: 3,
    text: 'Whatsapp',
    value: 'whatsapp'
  },
  {
    id: 4,
    text: 'Telegarm',
    value: 'telegram'
  },
  {
    id: 5,
    text: 'E-mail',
    value: 'email'
  }
]

// Peoples data
const isFree = reactive({
  reviewers: false,
  agreementers: false,
  watchers: false,
  final_boss: true
})
const peoplesList = ref({
  reviewers: [],
  agreementers: [],
  watchers: []
})
const peoplesSingle = ref({
  responsible: {},
  final_boss: {}
})
const peoplesDepartments = ref({
  reviewers: [],
  agreementers: [],
  watchers: []
})

const leaveRoute = ref('')
const isNoSaved = ref(false)
const isSaved = ref(false)
const isLoad = ref(false)
const isCounterpartyLoad = ref(false)
const isSummDoc = ref(false)
const isNewCounterparty = ref(false)

// Computed
const isNoData = computed(() => {
  const res = []
  for (const key in formData) {
    if (Object.hasOwnProperty.call(formData, key)) {
      const element = formData[key]
      const bool =
        typeof element === 'object'
          ? !!Object.values(element).length
          : Array.isArray(element)
          ? !!element.length
          : !!element

      if (key === 'priceType' || key === 'price' || key === 'currency') {
        if (isSummDoc.value) res.push(bool)
      } else if (
        key === 'counterparty' ||
        key === 'counterparty_id' ||
        key === 'counterparty_name' ||
        key === 'counterparty_info'
      ) {
        res.push(bool)
      } else {
        res.push(bool)
      }
    }
  }

  // Peoples
  res.push(!!peoplesSingle.value?.responsible?.id)
  res.push(!!peoplesSingle.value?.final_boss?.id)
  res.push(!!peoplesList.value?.reviewers?.length)
  res.push(!!peoplesDepartments.value?.reviewers?.length)
  res.push(!!peoplesList.value?.agreementers?.length)
  res.push(!!peoplesDepartments.value?.agreementers?.length)
  res.push(!!peoplesList.value?.watchers?.length)
  res.push(!!peoplesDepartments.value?.watchers?.length)

  return !res.some((el) => el === true)
})
const contacts = computed(() => store.getters?.profile?.contacts || [])
const optionsProjects = computed(() => store.getters.projects || [])
const optionsCounterparties = computed(() => store.getters.counterparties || [])
const isAnyLawyer = computed(
  () => store.getters?.profile?.lawyer_role === 'Senior Lawyer' || store.getters?.profile?.lawyer_role === 'Lawyer'
)

// Watcher
watch(
  () => formData.counterparty,
  () => {
    formData.counterparty_info = formData.counterparty?.info || formData.counterparty_info
    formData.counterparty_name = formData.counterparty?.name || formData.counterparty_name
  }
)
watch(isLoad, (val) => disableAllBtns('.modal', val))
// watch(isAddContact, (val) => setFocus('contacts', val))

// Created
store.dispatch('GET_USERS')
store.dispatch('GET_PROJECTS')
store.dispatch('GET_COUNTERPARTIES')

// Router before leave
onBeforeRouteLeave((to, from, next) => {
  if (!isNoData.value && !leaveRoute.value && !isSaved.value) {
    isNoSaved.value = true
    leaveRoute.value = to.fullPath
  } else {
    next()
  }
})

// Methods
function closeAction() {
  router.replace('/orders')
}

async function searchCounterparties(searchText) {
  if (searchText.length >= 3) {
    isCounterpartyLoad.value = true
    await store.dispatch('GET_COUNTERPARTIES_SEARCH', searchText)
    isCounterpartyLoad.value = false
  } else if (!searchText) {
    isCounterpartyLoad.value = true
    await store.dispatch('GET_COUNTERPARTIES')
    isCounterpartyLoad.value = false
  }
}

function saveAction() {
  isLoad.value = true
  isNoSaved.value = false
  isSaved.value = true
  const formDataFinal = new FormData()

  if (formData.type.value) formDataFinal.append('type', formData.type.value)
  if (formData.project?.id) formDataFinal.append('project_id', formData.project.id)

  if (typeof Number(formData.price.replace(/\s/g, '')) === 'number') {
    formDataFinal.append(formData.priceType, Number(formData.price.replace(/\s/g, '')))
  }
  if (formData.currency) formDataFinal.append('currency', formData.currency)
  if (formData.special_conditions) {
    const cleanedMessage = formData.special_conditions.split('<p>&nbsp;</p>').join('')
    formDataFinal.append('special_conditions', cleanedMessage)
  }

  formDataFinal.append('counterparty_name', formData.counterparty_name)
  formDataFinal.append(
    'counterparty_bin',
    isNewCounterparty.value ? formData.counterparty_bin : formData.counterparty?.value
  )
  formDataFinal.append('counterparty_info', formData.counterparty_info)
  if (formData.counterparty?.id) formDataFinal.append('counterparty_id', formData.counterparty.id)

  if (formData.comments) {
    const cleanedMessage = formData.comments.split('<p>&nbsp;</p>').join('')
    formDataFinal.append('comments', cleanedMessage)
  }
  // if (formData.deadline_date) formDataFinal.append('deadline_date', formData.deadline_date)

  if (formData.documents?.length) {
    for (let i = 0; i < formData.documents.length; i++) {
      const file = formData.documents[i]
      formDataFinal.append('documents[]', file.file)
    }
  }
  if (formData.newContactList?.length) {
    for (let i = 0; i < formData.newContactList.length; i++) {
      const item = formData.newContactList[i]
      formDataFinal.append(`contacts[${item.type}]`, item.value)
    }
  }

  orders
    .save(formDataFinal)
    .then(async () => {
      await store.dispatch('orders/GET_ROWS')
      await nextTick()
      router.replace('/orders')
      $busEmit('setToast', {
        type: 'blue',
        icon: 'check-circle',
        message: 'Заявка успешно сохранена в черновики, можете в любое время отправить ее Юристам.'
      })
      // leaveRoute.value = ''
    })
    .finally(() => {
      isLoad.value = false
    })
}
function submitAction() {
  isNoSaved.value = false
  if (isInvalidForm('form-add')) return (leaveRoute.value = '')
  isLoad.value = true
  const formDataFinal = new FormData()

  formDataFinal.append('type', formData.type.value)
  formDataFinal.append('project_id', formData.project.id)

  if (typeof Number(formData.price.replace(/\s/g, '')) === 'number') {
    formDataFinal.append(formData.priceType, Number(formData.price.replace(/\s/g, '')))
  }
  formDataFinal.append('currency', formData.currency)
  const special_conditions = formData.special_conditions.split('<p>&nbsp;</p>').join('')
  formDataFinal.append('special_conditions', special_conditions)

  formDataFinal.append('counterparty_name', formData.counterparty_name)
  formDataFinal.append(
    'counterparty_bin',
    isNewCounterparty.value ? formData.counterparty_bin : formData.counterparty?.value
  )
  formDataFinal.append('counterparty_info', formData.counterparty_info)
  if (formData.counterparty?.id) formDataFinal.append('counterparty_id', formData.counterparty.id)

  if (formData.comments) {
    const comments = formData.comments.split('<p>&nbsp;</p>').join('')
    formDataFinal.append('comments', comments)
  }
  // formDataFinal.append('deadline_date', formData.deadline_date)

  if (formData.documents?.length) {
    for (let i = 0; i < formData.documents.length; i++) {
      const file = formData.documents[i]
      formDataFinal.append('documents[]', file.file)
    }
  }
  if (formData.newContactList?.length) {
    for (let i = 0; i < formData.newContactList.length; i++) {
      const item = formData.newContactList[i]
      formDataFinal.append(`contacts[${item.type}]`, item.value)
    }
  }
  if (peoplesSingle.value?.responsible?.id) {
    formDataFinal.append(`responsible_id`, peoplesSingle.value.responsible.id)
  }
  if (peoplesSingle.value?.final_boss?.id) {
    formDataFinal.append(`final_boss`, peoplesSingle.value.final_boss.id)
  }

  // Reviewers
  if (isFree.reviewers) {
    if (peoplesList.value?.reviewers?.length) {
      for (let i = 0; i < peoplesList.value.reviewers.length; i++) {
        const el = peoplesList.value.reviewers[i]
        formDataFinal.append(`new_reviewers[]`, el.id)
      }
    }
  } else {
    if (peoplesDepartments.value?.reviewers?.length) {
      for (let i = 0; i < peoplesDepartments.value.reviewers.length; i++) {
        const el = peoplesDepartments.value.reviewers[i]
        formDataFinal.append(`review_departments[${i}][department_id]`, el.id)

        // Fixed users
        for (let index = 0; index < el.users.length; index++) {
          const user = el.users[index]
          formDataFinal.append(`review_departments[${i}][new_department_users][]`, user.id)
        }

        // Manual users
        for (let index = 0; index < el.manual_users.length; index++) {
          const user = el.manual_users[index]
          formDataFinal.append(`review_departments[${i}][new_manual_users][]`, user.id)
        }
      }
    }
  }

  // Agreementers
  if (isFree.agreementers) {
    if (peoplesList.value?.agreementers?.length) {
      for (let i = 0; i < peoplesList.value.agreementers.length; i++) {
        const el = peoplesList.value.agreementers[i]
        formDataFinal.append(`agreementers[${i}][id]`, el.id)
        formDataFinal.append(`agreementers[${i}][index]`, i)
      }
    }
  } else {
    if (peoplesDepartments.value?.agreementers?.length) {
      for (let i = 0; i < peoplesDepartments.value.agreementers.length; i++) {
        const el = peoplesDepartments.value.agreementers[i]
        formDataFinal.append(`agreement_departments[${i}][department_id]`, el.id)
        formDataFinal.append(`agreement_departments[${i}][department_index]`, i)

        // Manual users
        for (let index = 0; index < el.manual_users.length; index++) {
          const user = el.manual_users[index]
          formDataFinal.append(`agreement_departments[${i}][manual_users][${index}][id]`, user.id)
          formDataFinal.append(`agreement_departments[${i}][manual_users][${index}][index]`, index)
        }
      }
    }
  }

  // Watchers
  if (isFree.watchers) {
    if (peoplesList.value?.watchers?.length) {
      for (let i = 0; i < peoplesList.value.watchers.length; i++) {
        const el = peoplesList.value.watchers[i]
        formDataFinal.append(`new_watchers[]`, el.id)
      }
    }
  } else {
    if (peoplesDepartments.value?.watchers?.length) {
      for (let i = 0; i < peoplesDepartments.value.watchers.length; i++) {
        const el = peoplesDepartments.value.watchers[i]
        formDataFinal.append(`watcher_departments[${i}][department_id]`, el.id)

        // Fixed users
        for (let index = 0; index < el.users.length; index++) {
          const user = el.users[index]
          formDataFinal.append(`watcher_departments[${i}][new_department_users][]`, user.id)
        }

        // Manual users
        for (let index = 0; index < el.manual_users.length; index++) {
          const user = el.manual_users[index]
          formDataFinal.append(`watcher_departments[${i}][new_manual_users][]`, user.id)
        }
      }
    }
  }

  orders
    .create(formDataFinal)
    .then(async () => {
      await store.dispatch('orders/GET_ROWS')
      if (leaveRoute.value) {
        router.replace(leaveRoute.value)
      } else {
        leaveRoute.value = '/orders'
        await nextTick()
        router.replace('/orders')
      }

      // closeAction()
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: 'Заявка успешно создана, ожидайте пока Юристы возьмут ее в работу.'
      })
    })
    .finally(() => {
      isLoad.value = false
    })
}
function removeContact(index) {
  formData.newContactList.splice(index, 1)
}
function addNewContact() {
  formData.newContactList.push({ ...newContact })
  newContact.type = 'phone'
  newContact.value = ''
  isAddContact.value = false
}
</script>

<style lang="scss" scoped>
.form-add {
  min-height: calc(100vh - 300px);
  width: 800px;
  position: relative;

  // &__right {
  //   width: 300px;
  //   background-color: var(--form);
  //   border-left: var(--b1);

  //   &-help {
  //     position: sticky;
  //     top: 60px;
  //     z-index: 0;
  //   }
  // }

  &__section {
    // border-bottom: var(--b1);
    padding: 20px;

    &:last-child {
      border: 0;
    }

    .title {
      // color: var(--primary);
    }

    &-box {
      &-btn {
        &.active {
          background-color: var(--grey);
          border-radius: var(--br) var(--br) 0 0;
        }
      }
      &-body {
        background-color: var(--box);
        border-radius: 0 0 var(--br) var(--br);
        border: var(--b1);
        border-top: 0;
        padding: 20px;
      }
    }
  }
}
</style>
